<template>
    <TemplateLayout>
        <template slot="toolbar">
            <v-navigation-drawer v-model="drawer" absolute dark temporary>
                <v-list class="pt-0" dense>
                    <v-list class="pa-1">
                        <v-list-item tag="div">
                            <v-list-item-avatar>
                                <img src="../assets/user.png" />
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title>{{ user.nombres }} {{ user.apellidos }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                    <v-list-item @click="closeUser">
                        <v-list-item-action>
                            <v-icon right>mdi-close</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>CERRAR SESIÓN</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-navigation-drawer>
        </template>
        <router-view slot="pages" />
        <v-footer slot="footer" class="text-center">
            <v-spacer></v-spacer>
            <b class="caption">
                Powered
                <v-icon small>mdi-heart</v-icon><a href="https://innout.pe"> innout.pe</a> {{ versionNameDist }}
            </b>
            <v-spacer></v-spacer>
        </v-footer>
    </TemplateLayout>
</template>
<script>
import { mapState } from 'vuex'
import TemplateLayout from '@/layout/TemplateLayout'
import { VersionAPP } from '../utils/auth'

export default {
    components: {
        TemplateLayout
    },
    data() {
        return {
            mini: true,
            clipped: true,
            fixed: false,
            miniVariant: false,
            drawer: false,
            right: true,
            rightDrawer: false
        }
    },
    computed: {
        ...mapState('users', ['user']),
        ...mapState('bussines', ['bussines']),
        versionNameDist() {
            return VersionAPP
        },
        ...mapState('sedes', ['version'])
    },
    async created() {
        await this.$store.dispatch('category/getCategory')
        //console.log('this.$route.query.openModalVenta ', this.$route.query.openModalVenta)
        await this.$store.dispatch('utilities/getBancos')

        /*         if (openModalVenta) {
            this.$store.commit('ventas/SHOW_MODAL', { step: 1, state: true })
        } */
    },
    methods: {
        goRoute(v) {
            this.$router.replace(v)
        },
        closeUser() {
            this.$cookies.remove(this.versionNameDist)
            this.$store.commit('users/UPDATE_USER', { id: null, idSede: 1 })
            localStorage.clear()
            this.$router.push('/')
            //location.reload()
        }
    }
}
</script>
